import {
  AnimationClip,
  NumberKeyframeTrack,
  AnimationMixer,
  AnimationAction
} from 'three';

let mixer;
let action;

export function initializeAnimation(mesh, clip) {
  mixer = new AnimationMixer(mesh);
  action = mixer.clipAction(clip);
}

export function playAnimation() {
  action.play();
}

export function stopAnimation() {
  action.stop();
}

const fps = 60;

function modifiedKey(key) {
  const eyesKeys = [
    "eyeLookDownLeft",
    "eyeLookDownRight",
    "eyeLookInLeft",
    "eyeLookInRight",
    "eyeLookOutLeft",
    "eyeLookOutRight",
    "eyeLookUpLeft",
    "eyeLookUpRight"
  ];

  if (eyesKeys.includes(key)) return key;
  if (key.endsWith("Right")) return key.replace("Right", "_R");
  if (key.endsWith("Left")) return key.replace("Left", "_L");

  return key;
}

function createAnimation(recordedData, morphTargetDictionary, bodyPart) {
  if (recordedData.length === 0) return null;

  const animation = Array(Object.keys(morphTargetDictionary).length).fill([]).map(() => []);
  const time = [];

  recordedData.forEach((d, i) => {
    Object.entries(d.blendshapes).forEach(([key, value]) => {
      const modKey = modifiedKey(key);

      // Debug: Check if jawOpen exists in recordedData and its initial value
      if (!(modifiedKey(key) in morphTargetDictionary)) return;

      if (key == 'mouthClose') {
        value = -0.01;
      }

      if (key == 'mouthShrugUpper') {
        value = 0.2;
      }
      

      if (key == 'jawOpen') {
        value = 0.01;
      }

      if (key == 'mouthShrugLower') {
        value = 0.8;
      }

      if (key == 'mouthPucker') {
        value = 0.2;
      }

      animation[morphTargetDictionary[modKey]].push(value);
    });

    time.push(i / fps);
  });

  // Debug: Check animation array for jawOpen
  console.log(`Animation array for jawOpen: ${animation[morphTargetDictionary[modifiedKey('jawOpen')]]}`);

  const tracks = Object.entries(recordedData[0].blendshapes).reduce((acc, [key, value]) => {
    const modKey = modifiedKey(key);
    if (!(modKey in morphTargetDictionary)) return acc;

    const i = morphTargetDictionary[modKey];
    const track = new NumberKeyframeTrack(`${bodyPart}.morphTargetInfluences[${i}]`, time, animation[i]);

    acc.push(track);
    return acc;
  }, []);

  return new AnimationClip('animation', -1, tracks);
}

export default createAnimation;
